import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ibz from "../../image/ibz.png";
import market from "../../image/ibzmarkett.png";
import pay from "../../image/ibpay.png";
import lightImage from "./black.png";
import darkImage from "./white.png";
import yelo from "../../image/yelonxt.png";
import { ThemeContext } from "../../Context/ThemeContext";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});
function IbizaHeroButton() {
  const { t } = useTranslation();
  const { darkTheme } = useContext(ThemeContext);
  return (
    <div className=" addcard_bg">
      <div className="container">
        <Grid container spacing={2}>
          <Grid item md={6} lg={6} sm={12} xs={12} className="center">
            <div data-aos="fade-up" data-aos-duration="1000">
              <img src={ibz} style={{ width: "70%" }} className="avatar" />
            </div>
            <br />
            <br />
            <br />
          </Grid>
          <Grid
            item
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className="center"
            id="UtilityToken"
          >
            <br />
            <br />
            <div data-aos="fade-up" data-aos-duration="1000">
              <h5 className="undercard_head">Utility Token ($IBZ)</h5>
              <p className="undercard_para">{t("Utility_Token")}</p>
              <div className="start_under_button">
                <br />
                <button class="button-17 " role="button">
                  {t("Coming_Soon")}
                </button>
              </div>
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <Grid item md={6} lg={6} sm={12} xs={12} className="center">
            <div data-aos="fade-up" data-aos-duration="1000">
              <br />
              <br />
              <img src={pay} style={{ width: "70%" }} className="avatar" />
            </div>
            <br />
            <br />
            <br />
          </Grid>

          <Grid
            item
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className="center"
            id="IbizaPay"
          >
            <div data-aos="fade-up" data-aos-duration="1000">
              <br />
              <br />
              <h5 className="undercard_head">IbizaPay</h5>
              <p className="undercard_para">{t("Ibiza_Pay")}</p>
              <div className="start_under_button">
                <br />
                <button class="button-17 " role="button">
                  {t("explore")}
                </button>
              </div>
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
          </Grid>
          <br />
          <br />
          <br />
          <br />

          <Grid item md={6} lg={6} sm={12} xs={12} className="center">
            <div data-aos="fade-up" data-aos-duration="1000">
              <br />
              <br />
              <img
                src={market}
                style={{ width: "100%" }}
                className="avatar"
              />{" "}
              <br /> <br />
            </div>
            <br />
            <br />
            <br />
          </Grid>
          <Grid
            item
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className="center"
            id="IBZNFTMarketplace"
          >
            <br />
            <br />
            <div data-aos="fade-up" data-aos-duration="1000">
              <h5 className="undercard_heade ">IBZ NFT Marketplace</h5>
              <p className="undercard_parae">{t("NFT_Marketplace")}</p>
              <div className="start_under_buttone">
                <br />
                <button class="button-17 " role="button">
                  {t("WINTER")}
                </button>
              </div>
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <Grid
            item
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className="center"
            id="IbizaNXT"
          >
            <div data-aos="fade-up" data-aos-duration="1000">
              <br />
              <br />
              <h1 style={{ fontSize: "60px" }}>
                {" "}
                <img
                  src={darkTheme ? darkImage : lightImage}
                  alt="Change_Dark_Mode_And_Light_Mode_Image_Here"
                  style={{ width: "60%" }}
                  className="avatar"
                />
              </h1>
            </div>
            <br />
            <br />
            <br />
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12} className="center">
            <div data-aos="fade-up" data-aos-duration="1000">
              <br />
              <br />
              <h5 className="undercard_heade ">Ibiza NXT</h5>
              <p className="undercard_parae">{t("Ibiza_NXT")}</p>
              <div className="start_under_buttone">
                <br />
                <a href='https://nxt.ibizatoken.com/'> <button class="button-17 " role="button">
                {t("explore")}
              </button></a>
               
              </div>
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default IbizaHeroButton;
