import React, { useState } from "react";
import useDarkMode from "./components/use-dark-mode";
import cx from "classnames";
import "./App.css";
import { Helmet } from "react-helmet";
import "./style.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "rsuite/styles/index.less";

import IBIZAMAIN from "./pages/IBIZAMAIN";
import Detail_blog from "./components/Detail_blog";
import IbizaContact from "./pages/IbizaContact";
import Final from "./pages/Final";
import IbizaTerm from "./pages/IBizaTerm";
import theme from "./Theme";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeContext } from "./Context/ThemeContext";

function App() {
  const [darkTheme, setDarkTheme] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Ibiza Token - The Ibiza Web3 ecosystem</title>
        <meta
          name="Ibiza Token - The Ibiza Web3 ecosystem consists of a range of innovative solutions designed to connect local businesses, content creators, and tourists and to incentivize the adoption of blockchain technology on the island."
          content="Ibiza Token - The Ibiza Web3 ecosystem consists of a range of innovative solutions designed to connect local businesses, content creators, and tourists and to incentivize the adoption of blockchain technology on the island."
        />
      </Helmet>
      <ThemeContext.Provider value={{ darkTheme, setDarkTheme }}>
        <Router>
          <Routes>
            <Route path="/" exact={true} element={<IBIZAMAIN />} />
            <Route
              path="IbizaContact"
              exact={true}
              element={<IbizaContact />}
            />
            <Route path="IbizaTerm" exact={true} element={<IbizaTerm />} />
            <Route path="/detail/:slug" element={<Detail_blog />} />
            <Route path="*" element={<Final />} />
          </Routes>
        </Router>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
}

export default App;
