import React from "react";
import Roadmap from "../components/ContactUs/IbizaTerm";

const ibizaRoadmap = () => {
  return (
    <div>
      <Roadmap />
    </div>
  );
};

export default ibizaRoadmap;
