import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ibz from "../../image/ibz.png";
import market from "../../image/ibzmarkett.png";
import pay from "../../image/ibpay.png";
import yelo from "../../image/yelonxt.png";
import lightImage from "./black.png";
import darkImage from "./white.png";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import useDarkMode from "../use-dark-mode";
import { ThemeContext } from "../../Context/ThemeContext";

AOS.init({
  duration: 2500,
});

function IbizaHeroButton() {
  const { t } = useTranslation();

  const { darkTheme } = useContext(ThemeContext);

  return (
    <div>
      <div className=" addcard_bg">
        <div className="container">
          <Grid container spacing={8}>
            <Grid item md={6} lg={6} sm={12} xs={12} className="center" >
              <div data-aos="fade-down-right" data-aos-duration="1000">
                <img src={ibz} style={{ width: "100%" }} className="avatar" />
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              sm={12}
              xs={12}
              className="center"
              id="UtilityToken"
            >
              <div data-aos="fade-up" data-aos-duration="1000">
                <h5 className="undercard_head neon">Utility Token ($IBZ)</h5>
                <p className="undercard_para">{t("Utility_Token")}</p>
                <div className="start_under_button">
                  <button class="button-17 " role="button">
                    {t("Coming_Soon")}
                  </button>
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              sm={12}
              xs={12}
              className="center"
              id="IbizaPay"
            >
              <div data-aos="fade-up" data-aos-duration="1000">
                <h5 className="undercard_heade neon">IbizaPay</h5>
                <p className="undercard_parae">{t("Ibiza_Pay")}</p>
                <div className="start_under_buttone">
                  <button class="button-17 " role="button">
                    {t("explore")}
                  </button>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </Grid>
            <Grid item md={6} lg={6} sm={12} xs={12} className="center">
              <div data-aos="fade-down-left" data-aos-duration="1000">
                <img
                  src={pay}
                  style={{ width: "90%", position: "relative", top: "-30px" }}
                  className="avatar"
                />
              </div>
              <br />
              <br />
              <br />
              <br />
            </Grid>

            <Grid
              item
              md={6}
              lg={6}
              sm={12}
              xs={12}
              className="center "
              id="IBZNFTMarketplace"
            >
              <div data-aos="fade-down-right" data-aos-duration="1000">
                <img
                  src={market}
                  style={{ width: "130%" }}
                  className="avatar"
                />
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>

            <Grid item md={6} lg={6} sm={12} xs={12} className="center">
              <div data-aos="fade-up" data-aos-duration="1000">
                <h5 className="undercard_head neon">IBZ NFT Marketplace</h5>
                <p className="undercard_para">{t("NFT_Marketplace")}</p>
                <div className="start_under_button">
                  <button class="button-17 " role="button">
                    {t("WINTER")}
                  </button>
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              sm={12}
              xs={12}
              className="center"
              id="IbizaNXT"
            >
              <div data-aos="fade-up" data-aos-duration="1000">
                <h5 className="undercard_heade neon">Ibiza NXT</h5>
                <p className="undercard_parae">{t("Ibiza_NXT")}</p>
                <div className="start_under_buttone">
                <a href='https://nxt.ibizatoken.com/'><button className="button-17" role="button">
                {t("explore")}
              </button></a>
                  
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              sm={12}
              xs={12}
              className="center"
              style={{ textAlign: "center" }}
            >
              <div data-aos="fade-down-left" data-aos-duration="1000">
                <img
                  src={darkTheme ? darkImage : lightImage}
                  alt="Change_Dark_Mode_And_Light_Mode_Image_Here"
                  style={{ width: "60%" }}
                  className="avatar"
                />
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default IbizaHeroButton;
