import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});
function IbizaHeroButton() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Grid container>
        <Grid item md={12} lg={12} sm={12} xs={12} style={{ margin: "50px" }}>
          <div data-aos="fade-up" data-aos-duration="1000">
            <h1 className="utilityhead neon">{t("Elevating")}</h1>
          </div>
          <br />
          <br />
          <div data-aos="fade-up" data-aos-duration="1000">
            <p className="utilityhead_under">{t("Elevating_under")}</p>
          </div>
        </Grid>
      </Grid>

      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default IbizaHeroButton;
