import React, { useState, useEffect, useContext } from "react";

import { NavDropdown } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { FaCog } from "react-icons/fa";
import Toggle from "../components/dark-mode-toggle";
import useDarkMode from "../components/use-dark-mode";
import "../components/styles.css";
import cx from "classnames";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import images from "./lo.png";
import { FiSettings } from "react-icons/fi";
import "./Navbars.css";
import { ThemeContext } from "../Context/ThemeContext";

const Navbar = () => {
  const { setDarkTheme } = useContext(ThemeContext);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const [darkMode, setDarkMode] = useDarkMode(false);

  useEffect(() => {
    setDarkTheme(darkMode);
  }, [darkMode]);

  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [navSize, setnavSize] = useState("10rem");
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#080a3e") : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("5rem") : setnavSize("10rem");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const languages = [
    {
      code: "fr",
      name: "Spanish",
      country_code: "fr",
      flag: "Image/spain.png",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
      flag: "Image/english.png",
    },
  ];

  const GlobeIcon = ({ width = 24, height = 24 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className="bi bi-globe"
      viewBox="0 0 16 16"
    >
      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
    </svg>
  );

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const releaseDate = new Date("2021-03-07");

  const number_of_days = Math.floor(2 / (1000 * 60 * 60 * 24));

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images} alt="app__logo" />
      </div>

      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a
            href="#UtilityToken"
            style={{ color: "#fff", fontWeight: "800", textDecoration: "none" }}
          >
            Utility Token
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a
            href="#IbizaPay"
            style={{ color: "#fff", fontWeight: "800", textDecoration: "none" }}
          >
            IbizaPay
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a
            href="#IBZNFTMarketplace"
            style={{ color: "#fff", fontWeight: "800", textDecoration: "none" }}
          >
            {t("welcome_message")}
          </a>
        </li>
        <li className="p__opensans">
          <a
            href="#IbizaNXT"
            style={{ color: "#fff", fontWeight: "800", textDecoration: "none" }}
          >
            Ibiza NXT
          </a>
        </li>
        <li className="p__opensans">
          <a
            href="#Press"  
            style={{ color: "#fff", fontWeight: "800", textDecoration: "none" }}
          >
            {t("welcome_message5")}
          </a>
        </li>
        <li className="p__opensans">
          <a
            href="#faq"
            style={{ color: "#fff", fontWeight: "800", textDecoration: "none" }}
          >
            {t("welcome_message6")}
          </a>
        </li>
        <li className="p__opensans">
          <a
            href="#roadmap"
            style={{ color: "#fff", fontWeight: "800", textDecoration: "none" }}
          >
            {t("welcome_message7")}
          </a>
        </li>
        <li className="p__opensans">
          <a
            href="./IbizaContact"
            style={{ color: "#fff", fontWeight: "800", textDecoration: "none" }}
          >
            {t("welcome_message8")}
          </a>
        </li>
        <li>
          {" "}
          <NavDropdown
            id="nav-dropdown-dark-example"
            title={
              <span>
                <FiSettings className="my-icon" />
              </span>
            }
            menuVariant=""
            show={showDropdown}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            menuAlign="right"
            className={cx("App", darkMode ? "darkMode" : "lightMode")}
          >
            <li
              style={{ color: "#fff", fontWeight: "500" }}
              className={cx("App", darkMode ? "darkMode" : "lightMode")}
            >
              <span
                className="dropdown-item-text"
                style={{ justifyContent: "center" }}
              >
                <Toggle darkMode={darkMode} setDarkMode={setDarkMode} />
              </span>
            </li>

            <li style={{ fontWeight: "600" }}>
              <span
                className="dropdown-item-text"
                style={{ justifyContent: "center" }}
              >
                {t("language")}
              </span>
            </li>
            {languages.map(({ code, name, flag }) => (
              <li key={flag} style={{}}>
                <a
                  href="#"
                  className={classNames("dropdown-item", {
                    disabled: currentLanguageCode === code,
                  })}
                  onClick={() => {
                    i18next.changeLanguage(code);
                  }}
                  style={{
                    position: "relative",
                    right: "35px",
                    background: "transparent",
                    fontWeight: "900",
                  }}
                >
                  <a
                    className={`flag-icon flag-icon-${flag} mx-2`}
                    style={{
                      opacity: currentLanguageCode === code ? 0.5 : 1,
                    }}
                  ></a>
                  <img
                    src={flag}
                    style={{ height: "40px", borderRadius: "50%" }}
                  />{" "}
                  {name}
                </a>
              </li>
            ))}
            <br />
          </NavDropdown>
        </li>
      </ul>

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <AiOutlineClose
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <br />
            <br />

            <ul className="app__navbar-smallscreen_links">
              <li className="li">
                <a
                  href="#UtilityToken"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "800" }}
                >
                  Utility Token
                </a>
              </li>
              <li className="li">
                <a
                  href="#IbizaPay"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "800" }}
                >
                  IbizaPay
                </a>
              </li>{" "}
              <li className="li">
                <a
                  href="#IBZNFTMarketplace"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "800" }}
                >
                  NFT Marketplace
                </a>
              </li>{" "}
              <li className="li">
                <a
                href="#IbizaNXT"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "800" }}
                >
                  Ibiza NXT
                </a>
              </li>
             
              <li className="li">
                <a
                href="#Press"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "800" }}
                >
                  {t("welcome_message5")}
                </a>
              </li>
              <li className="li">
                <a
                   href="#faq"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "800" }}
                >
                  {t("welcome_message6")}
                </a>
              </li>
              <li className="li">
                <a
                href="#roadmap"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "800" }}
                >
                  {t("welcome_message7")}
                </a>
              </li>
              <li className="li">
                <a
                href="./IbizaContact"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "800" }}
                >
                  {t("welcome_message8")}
                </a>
              </li>
              <li className="li">
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={
                    <span>
                      <FiSettings className="my-icon" />
                    </span>
                  }
                  menuVariant=""
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  menuAlign="right"
                  className={cx("App", darkMode ? "darkMode" : "lightMode")}
                >
                  <li
                    style={{ color: "#fff", fontWeight: "500" }}
                    className={cx("App", darkMode ? "darkMode" : "lightMode")}
                  >
                    <span
                      className="dropdown-item-text"
                      style={{ justifyContent: "center" }}
                    >
                      <Toggle darkMode={darkMode} setDarkMode={setDarkMode} />
                    </span>
                  </li>

                  <li style={{ fontWeight: "500" }}>
                    <span
                      className="dropdown-item-text"
                      style={{ justifyContent: "center" }}
                    >
                      {t("language")}
                    </span>
                  </li>
                  {languages.map(({ code, name, flag }) => (
                    <li key={flag}>
                      <a
                        href="#"
                        className={classNames("dropdown-item", {
                          disabled: currentLanguageCode === code,
                        })}
                        onClick={() => {
                          i18next.changeLanguage(code);
                        }}
                      >
                        <a
                          className={`flag-icon flag-icon-${flag} mx-2`}
                          style={{
                            opacity: currentLanguageCode === code ? 0.5 : 1,
                          }}
                        ></a>
                        <img src={flag} style={{ height: "15px" }} /> {name}
                      </a>
                    </li>
                  ))}
                  <br />
                </NavDropdown>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
